import React, { useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import styled from 'styled-components';
import { ContentfulPageWhereToBuy, ContentfulEntry } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';
import { getPlainTextFromMicrocopyField } from '@/utils/helpers';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import {
  RichText,
  TextCta,
  RichTextColumns,
  Undefined,
} from '@/components/contentful/components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TextField, MenuItem } from '@material-ui/core';
import { Phone, Computer, Public, AlternateEmail } from '@material-ui/icons';
import * as Constants from '@/types/Constants';

const Container = styled.div`
  .partner-card ul li,
  .seller-card ul li {
    display: flex;
  }
  .partner-card ul li a,
  .seller-card ul li a {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tabContainer {
    justify-content: flex-start;

    .tabPanel {
      flex-grow: 1;
    }

    .tabContent {
      flex-grow: 1;
      max-width: 620px;
      padding-right: 20px;
    }

    .cardContent {
      flex-grow: 1;
      max-width: 500px;
      min-width: 320px;

      > div + div {
        margin-top: 20px;
      }
    }

    .tabsList {
      flex-shrink: 0;
      width: 100%;

      .MuiTabs-indicator {
        right: unset;
        left: 10px;
        width: 5px;
        max-height: 36px;
        margin-top: 8px;
        background-color: #006dff;
      }

      .singleTab {
        &.Mui-selected {
          background-color: rgba(0, 109, 255, 0.05);
        }

        &.MuiTab-root {
          text-transform: none;
          font-family: arboria, Helvetica, Arial, Tahoma, sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #006dff;
          text-align: left;
          border-radius: 5px;
          margin-bottom: 24px;
          padding: 12px 18px 12px 28px;
          width: 100%;
        }
        .MuiTab-wrapper {
          align-items: flex-start;
        }
      }
    }
    @media (max-width: ${(props) => props.theme.breakpoint.md}) {
      .MuiTabs-flexContainerVertical {
        flex-direction: row;
      }
      .tabsList .MuiTabs-indicator {
        display: none;
      }
    }
  }
`;

const SectionsWrapper = styled.div`
  background: ${(props) => props.theme.backgroundGradient.greyReversed};
  h2 {
  }
  .richTextColumn {
  }
  @media (max-width: ${(props) => props.theme.breakpoint.lg}) {
    .richTextColumn {
      grid-column: span 12 / span 12 !important;
      display: flex;
    }
  }
`;

const LinesWrapper = styled.div`
  transform: translateX(24px);
`;

const RichTextContainer = styled.div`
  a {
    color: ${(props) => props.theme.color.primaryMain};
  }
  a:hover,
  a:focus {
    color: ${(props) => props.theme.color.primaryDark};
  }
`;

// to allow list items to override
const Paragraph = styled.p`
margin-bottom:1.5em;
}
`;

// to handle contentful forcing <p> tags into <li>
const ListItemWrapper = styled.li`
  p {
    margin-bottom: 0;
  }
`;

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

interface PageWhereToBuyProps {
  data?: ContentfulPageWhereToBuy;
  sellers?: Array<object>;
  partners?: Array<object>;
}
const PageWhereToBuy: React.FC<PageWhereToBuyProps> = (props) => {
  const { data, sellers, partners } = props;
  const [value, setValue] = useState(0);
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [disableState, setDisableState] = useState(false);

  const handleTabChange = (event, newValue) => {
    setCountry(null);
    setState(null);
    setValue(newValue);
  };

  const handleCountryChange = (newCountry) => {
    setCountry(newCountry);
    setState(null);

    if (newCountry === 'Canada' || newCountry === 'United States') {
      setDisableState(false);
    } else {
      setDisableState(true);
    }
  };

  // strip out duplicate sellers in region
  const checkIsUnique = [];

  const uniqueSellers = sellers.filter((element) => {
    const sellerID = element.UserID;
    const sellerRegion = element.SellerRegion;
    const uniqueSellerID = sellerID + sellerRegion;
    const isDuplicate = checkIsUnique.includes(uniqueSellerID);

    if (!isDuplicate) {
      checkIsUnique.push(uniqueSellerID);
      return true;
    }
    return false;
  });

  const handleStateChange = (newState) => {
    setState(newState);
  };

  /* check for query param "tab" to set initial tab, if not first option */
  const [initialTab] = useQueryParam('tab', StringParam);
  React.useEffect(() => {
    if (initialTab === 'sales') {
      setValue(1);
    }
  }, []);

  const Text = ({ children }) => <Paragraph>{children}</Paragraph>;
  const UL_list = ({ children }) => (
    <ul className="list-disc list-outside ml-6 mb-6">{children}</ul>
  );
  const OL_list = ({ children }) => (
    <ol className="list-decimal list-outside ml-6 mb-6">{children}</ol>
  );
  const ListItem = ({ children }) => (
    <ListItemWrapper>{children}</ListItemWrapper>
  );

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <em>{text}</em>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.UL_LIST]: (node, children) => <UL_list>{children}</UL_list>,
      [BLOCKS.OL_LIST]: (node, children) => <OL_list>{children}</OL_list>,
      [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
      [BLOCKS.HR]: (node, children) => <hr className="my-8 border-grey2" />,
      [INLINES.HYPERLINK]: (node) => {
        return <a href={node.data.uri}>{node.content[0].value}</a>;
      },
    },
    renderText: (text) =>
      text
        .replace(/\u2028/g, '')
        .split('\n')
        .flatMap((text, i) => [i > 0 && <br key={`break-${i}`} />, text]),
  };

  const countryCheck = data?.countriesData?.map(({ content }) => content[0]);

  const getRegions = (country) => {
    if (!country) {
      return [];
    }
    const countryData = data.countriesData.find(
      ({ content }) => content[1] === country,
    );

    return countryData.content[2].split('|').map((regionPair) => {
      const [regionName, regionShortCode = null] = regionPair.split('~');
      return { regionName, regionShortCode };
    });
  };

  /* 

  PARTNER: 
      CompanyCountry
      CompanyEmail
      CompanyName
      CompanyPhone
      CompanyPhoto
      CompanyRegion
      CompanyRegionType
      CompanyWebsite

  SELLER:
      Persona
      SellerCountry
      SellerEmail
      SellerLoB
      SellerName
      SellerPhone
      SellerPhoto
      SellerRegion
      SellerRegionType

  */
  let mioContact = (
    <ul>
      <li>
        <span>
          <Phone className="w-4 mr-2" />
        </span>
        Direct: +49 221 261030
      </li>
      <li>
        <span>
          <Phone className="w-4 mr-2" />
        </span>
        Toll-free: +800 855 880 9115
      </li>
      <li>
        <span>
          <Phone className="w-4 mr-2" />
        </span>
        Germany support : +49 221 261 03451
        <br />
        France support : 0800 916724
        <br />
        UK support : 0800 0314065
        <br />
      </li>
      <li>
        <span>
          <Computer className="w-4 mr-2" />
        </span>
        <a
          href="/contact-us"
          className="text-primaryMain hover:text-primaryDark"
        >
          Contact Us
        </a>
      </li>
    </ul>
  );

  if (country === 'Canada' || country === 'United States') {
    mioContact = (
      <ul>
        <li>
          <span>
            <Phone className="w-4 mr-2" />
          </span>
          Direct: 1-519-513-2407
        </li>
        <li>
          <span>
            <Phone className="w-4 mr-2" />
          </span>
          Toll-free: 1-877-646-8476
        </li>
        <li>
          <span>
            <Phone className="w-4 mr-2" />
          </span>
          Support direct: 1-855-360-7752
        </li>
        <li>
          <span>
            <Computer className="w-4 mr-2" />
          </span>
          <a
            href="/contact-us"
            className="text-primaryMain hover:text-primaryDark"
          >
            Contact Us
          </a>
        </li>
      </ul>
    );
  }

  // let currentSellerName, currentSellerRegion;

  return (
    <Container className="container mb-md">
      <div className="tabContainer w-full md:flex gap-sm">
        <div className="w-full md:w-1/4 flex-shrink-0 text-center my-sm md:my-0 md:text-left relative">
          <Typography
            as="h1"
            variant="h3"
            className="text-primaryMain font-normal md:mt-md"
          >
            {data?.headline}
          </Typography>
          <LinesWrapper className="hidden md:block absolute top-0 right-0">
            <svg
              width="49"
              height="150"
              viewBox="0 0 49 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 2L25 104"
                stroke="#00A566"
                strokeWidth="3"
                strokeLinecap="round"
              />
              <line
                x1="1.5"
                y1="-1.5"
                x2="14.5"
                y2="-1.5"
                transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 24.5 133.215)"
                stroke="#00A566"
                strokeWidth="3"
                strokeLinecap="round"
              />
              <circle
                r="2"
                transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 25.5 147.215)"
                fill="#00A566"
              />
              <path
                d="M47 64L47 2"
                stroke="#006DFF"
                strokeWidth="3"
                strokeLinecap="round"
              />
              <path
                d="M2 42L2 2"
                stroke="#FFB700"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          </LinesWrapper>

          <Tabs
            orientation="vertical"
            variant="standard"
            value={value}
            onChange={handleTabChange}
            aria-label="Sales option tabs"
            className="tabsList mt-md"
          >
            <Tab
              key="tab-1"
              label={data?.tab1Title}
              className="singleTab"
              {...a11yProps(0)}
            />
            <Tab
              key="tab-2"
              label={data?.tab2Title}
              className="singleTab"
              {...a11yProps(1)}
            />
          </Tabs>
        </div>
        <div className="pl-0 md:pl-xs">
          <div
            role="tabpanel"
            hidden={value !== 0}
            id="vertical-tabpanel-0"
            className="tabPanel"
            aria-labelledby="vertical-tab-0"
          >
            <Typography
              as="h2"
              variant="h2"
              className="text-primaryMain font-light md:mt-md mb-sm"
            >
              {data?.tab1Title}
            </Typography>
            {data?.tab1Description?.raw && (
              <RichTextContainer>
                {renderRichText(
                  data.tab1Description as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                  options,
                )}
              </RichTextContainer>
            )}
            <div className="grid grid-cols-2 gap-4 my-sm">
              <div>
                <TextField
                  select
                  id="country"
                  name="country"
                  label={getPlainTextFromMicrocopyField(data?.countryLabel)}
                  variant="outlined"
                  value={country}
                  onChange={(e) => {
                    handleCountryChange(e.target.value);
                  }}
                  fullWidth
                >
                  <MenuItem value={''}>Please select a Country...</MenuItem>
                  {data?.countriesData?.map(({ content }, index) => (
                    <MenuItem
                      key={`country-${index}-${content[1]}`}
                      value={content[1]}
                    >
                      {content[0]}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div>
                <TextField
                  select
                  id="state"
                  name="state"
                  label={getPlainTextFromMicrocopyField(data?.stateLabel)}
                  variant="outlined"
                  value={state}
                  onChange={(e) => {
                    handleStateChange(e.target.value);
                  }}
                  disabled={disableState}
                  fullWidth
                >
                  <MenuItem value={''}>
                    Please select a State/Province...
                  </MenuItem>
                  {getRegions(country).map(
                    ({ regionName, regionShortCode }) => (
                      <MenuItem key={regionShortCode} value={regionShortCode}>
                        {regionName}
                      </MenuItem>
                    ),
                  )}
                </TextField>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-4">
              {partners?.map((partner, index) => {
                let makeVisible = 'hidden';

                if (country) {
                  if (countryCheck.includes(partner.CompanyCountry)) {
                    if (
                      country === partner.CompanyCountry &&
                      country !== 'Canada' &&
                      country !== 'United States'
                    ) {
                      makeVisible = '';
                    }
                    if (state) {
                      makeVisible = '';
                      if (state !== partner.CompanyRegion) {
                        makeVisible = 'hidden';
                      }
                    }
                  } else {
                    if (country === 'Other') {
                      makeVisible = '';
                    }
                  }
                }

                let countryString;
                if (partner.CompanyCountry) {
                  countryString = ', ' + partner.CompanyCountry;
                }
                return (
                  <div
                    key={`partner-${index}`}
                    className={`col-span-12 sm:col-span-6 xl:col-span-4 p-4 rounded-md border partner-card partner-${index} ${makeVisible}`}
                  >
                    {/* <img
                      src={`https://miovision.my.salesforce.com${partner.CompanyPhoto}`}
                    /> */}
                    <p className="text-lg leading-tight mb-4">
                      <strong>{partner.CompanyName}</strong>
                    </p>
                    <ul className="text-sm">
                      {partner.CompanyPhone && (
                        <li>
                          <span>
                            <Phone className="w-4 mr-2" />
                          </span>
                          {partner.CompanyPhone}
                        </li>
                      )}
                      {partner.CompanyEmail && (
                        <li>
                          <span>
                            <AlternateEmail className="w-4 mr-2" />
                          </span>
                          <a
                            href={`mailto:${partner.CompanyEmail}`}
                            className="text-primaryMain hover:text-primaryDark"
                          >
                            {partner.CompanyEmail}
                          </a>
                        </li>
                      )}
                      {partner.CompanyWebsite && (
                        <li>
                          <span>
                            <Computer className="w-4 mr-2" />
                          </span>
                          <a
                            href={partner.CompanyWebsite}
                            target="_blank"
                            rel="noreferrer"
                            className="text-primaryMain hover:text-primaryDark"
                          >
                            Website
                          </a>
                        </li>
                      )}
                      {partner.CompanyRegion && (
                        <li>
                          <span>
                            <Public className="w-4 mr-2" />
                          </span>
                          {partner.CompanyRegion}
                          {countryString}
                        </li>
                      )}
                    </ul>
                  </div>
                );
              })}
              {!country && (
                <div
                  className={`col-span-12 sm:col-span-6 xl:col-span-4 p-4 rounded-md border partner-card partner-miovision flex items-center justify-center`}
                >
                  <span>
                    <Public className="w-12 mb-4 block mx-auto" />
                    Choose a Country!
                  </span>
                </div>
              )}
              {(country === 'Canada' || country === 'United States') && !state && (
                <div
                  className={`col-span-12 sm:col-span-6 xl:col-span-4 p-4 rounded-md border partner-card partner-miovision partner-c flex items-center justify-center`}
                >
                  <span>
                    <Public className="w-12 mb-4 block mx-auto" />
                    Choose a {country === 'Canada' ? 'Province' : 'State'}.
                  </span>
                </div>
              )}
              {country && (
                <div
                  className={`col-span-12 sm:col-span-6 xl:col-span-4 p-4 rounded-md border partner-card partner-miovision`}
                >
                  <p className="text-lg leading-tight mb-4">
                    <strong>Miovision</strong>
                  </p>
                  {mioContact}
                </div>
              )}
            </div>
            <SectionsWrapper className="sections mt-sm p-sm rounded">
              {data?.tab1Sections?.map(
                (contentfulComponent: ContentfulEntry) => {
                  let element;
                  const key = `component-${contentfulComponent.id}`;
                  switch (contentfulComponent?.internal?.type) {
                    case Constants.COMPONENT_RICH_TEXT:
                      element = (
                        <RichText key={key} data={contentfulComponent} />
                      );
                      break;
                    case Constants.COMPONENT_TEXT_CTA:
                      element = (
                        <TextCta key={key} data={contentfulComponent} />
                      );
                      break;
                    case Constants.COMPONENT_RICH_TEXT_COLUMNS:
                      element = (
                        <RichTextColumns key={key} data={contentfulComponent} />
                      );
                      break;
                    default:
                      element = (
                        <Undefined
                          key={key}
                          message={`ComponentMapper: "${contentfulComponent?.internal?.type}" not defined.`}
                        />
                      );
                  }
                  return element;
                },
              )}
            </SectionsWrapper>
          </div>

          <div
            role="tabpanel"
            hidden={value !== 1}
            id="vertical-tabpanel-1"
            className="tabPanel"
            aria-labelledby="vertical-tab-1"
          >
            <Typography
              as="h2"
              variant="h2"
              className="text-primaryMain font-light md:mt-md mb-sm"
            >
              {data?.tab2Title}
            </Typography>
            {data?.tab2Description?.raw && (
              <RichTextContainer>
                {renderRichText(
                  data.tab2Description as unknown as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                  options,
                )}
              </RichTextContainer>
            )}

            <div className="grid grid-cols-2 gap-4 my-sm">
              <div>
                <TextField
                  select
                  id="country"
                  name="country"
                  label={getPlainTextFromMicrocopyField(data?.countryLabel)}
                  variant="outlined"
                  value={country}
                  onChange={(e) => {
                    handleCountryChange(e.target.value);
                  }}
                  fullWidth
                >
                  <MenuItem value={''}>Please select a Country...</MenuItem>
                  {data?.countriesData?.map(({ content }, index) => (
                    <MenuItem
                      key={`country-${index}-${content[1]}`}
                      value={content[1]}
                    >
                      {content[0]}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div>
                <TextField
                  select
                  id="state"
                  name="state"
                  label={getPlainTextFromMicrocopyField(data?.stateLabel)}
                  variant="outlined"
                  value={state}
                  onChange={(e) => {
                    handleStateChange(e.target.value);
                  }}
                  disabled={disableState}
                  fullWidth
                >
                  <MenuItem value={''}>
                    Please select a State/Province...
                  </MenuItem>
                  {getRegions(country).map(
                    ({ regionName, regionShortCode }) => (
                      <MenuItem key={regionShortCode} value={regionShortCode}>
                        {regionName}
                      </MenuItem>
                    ),
                  )}
                </TextField>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-4">
              {uniqueSellers?.map((seller, index) => {
                let makeVisible = 'hidden';

                if (country) {
                  if (countryCheck.includes(seller.SellerCountry)) {
                    if (
                      country === seller.SellerCountry &&
                      country !== 'Canada' &&
                      country !== 'United States'
                    ) {
                      makeVisible = '';
                    }
                    if (state) {
                      makeVisible = '';
                      if (state !== seller.SellerRegion) {
                        makeVisible = 'hidden';
                      }
                    }
                  } else {
                    if (country === 'Other') {
                      makeVisible = '';
                    }
                  }
                }

                let countryString;
                if (seller.SellerCountry) {
                  countryString = ', ' + seller.SellerCountry;
                }

                const attnName = seller.SellerName.replaceAll(' ', '-');

                let sellerLoB = 'Intelligent Transportation Solutions';
                if (seller.SellerLoB === 'TE') {
                  sellerLoB = 'Traffic Engineering Solutions';
                }

                return (
                  <div
                    key={`seller-${index}`}
                    className={`col-span-12 sm:col-span-6 xl:col-span-4 p-4 rounded-md border seller-card seller-${index} ${makeVisible}`}
                  >
                    {/* <img
                      src={`https://miovision.my.salesforce.com${partner.CompanyPhoto}`}
                    /> */}
                    <div className="text-xl mb-4">
                      <span className="block font-bold">
                        {seller.SellerName}
                      </span>
                      <span className="mt-2 text-xs leading-tight block">
                        <strong>{seller.Persona}</strong>
                        <br />
                        {sellerLoB}
                      </span>
                      <hr className="my-3" />
                    </div>
                    <ul className="text-sm">
                      {seller.SellerRegion && (
                        <li>
                          <span>
                            <Public className="w-4 mr-2" />
                          </span>
                          {seller.SellerRegion}
                          {countryString}
                        </li>
                      )}
                      <li>
                        <span>
                          <AlternateEmail className="w-4 mr-2" />
                        </span>
                        <a
                          href={`/contact-seller/?attn=${attnName}&userID=${seller.UserID}`}
                          className="text-primaryMain hover:text-primaryDark"
                        >
                          Contact salesperson
                        </a>
                      </li>
                    </ul>
                  </div>
                );
              })}
              {!country && (
                <div
                  className={`col-span-12 sm:col-span-6 xl:col-span-4 p-4 rounded-md border partner-card partner-miovision flex items-center justify-center`}
                >
                  <span>
                    <Public className="w-12 mb-4 block mx-auto" />
                    Choose a Country.
                  </span>
                </div>
              )}
              {(country === 'Canada' || country === 'United States') && !state && (
                <div
                  className={`col-span-12 sm:col-span-6 xl:col-span-4 p-4 rounded-md border partner-card partner-miovision flex items-center justify-center`}
                >
                  <span>
                    <Public className="w-12 mb-4 block mx-auto" />
                    Choose a {country === 'Canada' ? 'Province' : 'State'}.
                  </span>
                </div>
              )}
              {country && (
                <div
                  className={`col-span-12 sm:col-span-6 xl:col-span-4 p-4 rounded-md border partner-card partner-miovision`}
                >
                  <p className="text-lg leading-tight mb-4">
                    <strong>Miovision</strong>
                  </p>
                  {mioContact}
                </div>
              )}
            </div>
            <div className="sections mt-sm">
              {data?.tab2Sections?.map(
                (contentfulComponent: ContentfulEntry) => {
                  let element;
                  const key = `component-${contentfulComponent.id}`;
                  switch (contentfulComponent?.internal?.type) {
                    case Constants.COMPONENT_RICH_TEXT:
                      element = (
                        <RichText key={key} data={contentfulComponent} />
                      );
                      break;
                    case Constants.COMPONENT_TEXT_CTA:
                      element = (
                        <TextCta key={key} data={contentfulComponent} />
                      );
                      break;
                    case Constants.COMPONENT_RICH_TEXT_COLUMNS:
                      element = (
                        <RichTextColumns key={key} data={contentfulComponent} />
                      );
                      break;
                    default:
                      element = (
                        <Undefined
                          key={key}
                          message={`ComponentMapper: "${contentfulComponent?.internal?.type}" not defined.`}
                        />
                      );
                  }
                  return element;
                },
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default PageWhereToBuy;
